import React from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../components/slider/slider';
import AcceptingNewPatientsSwitcher from '../accepting-new-patients-switcher';
import { INNER_MODAL } from '../../constants';

const InnerContent = ({
  modal: {tempModalData, setTempModalData},
  toggle
}) => {

  const toggleOn = toggle.toggleData.acceptingNewPatients;
  const saveOverallValue = value => setTempModalData({...tempModalData, overallPatientsLimit: value});
  const saveDailyValue = value => setTempModalData({...tempModalData, dailyNewPatientLimit: value});

  return (
    <div className="patient-limits-inner-component">
      <AcceptingNewPatientsSwitcher
        toggle={toggle}
        position={'in'}
      />
      <div className="patient-limit">
        <div className="title">{INNER_MODAL.active}</div>
        <div className="description">{INNER_MODAL.active_patient_limit_guidance}</div>
        <div className="limit-slider with-box">
          <div className="limit-text">{INNER_MODAL.limit}</div>
          <div className="range-slider">
            <Slider
              value={tempModalData.overallPatientsLimit}
              setValue={saveOverallValue}
              min={1}
              max={tempModalData.defaultOverall}
              current={tempModalData.currentPatients}
              showCurrentPatients
              useInputBox
              isFrozen={!toggleOn}
            />
          </div>
        </div>
      </div>

      <div className="patient-limit">
        <div className="title">{INNER_MODAL.daily_limit}</div>
        <div className="description">{INNER_MODAL.warning}</div>
        <div className="limit-slider">
          <div className="limit-text">{INNER_MODAL.limit}</div>
          <div className="range-slider">
            <Slider
              min={1}
              max={tempModalData.defaultDaily}
              value={tempModalData.dailyNewPatientLimit}
              setValue={saveDailyValue}
              current={tempModalData.dailyNewPatientLimit}
              isFrozen={!toggleOn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

InnerContent.propTypes = {
  toggle: PropTypes.shape({
    toggleData: PropTypes.shape({
      acceptingNewPatients:      PropTypes.bool,
      tooltipMessage:            PropTypes.array,
    }),
    updateToggleValue:           PropTypes.func
  }),
  modal: PropTypes.shape({
    tempModalData: PropTypes.shape({
      role:                      PropTypes.string,
      providerId:                PropTypes.number,
      displayModal:              PropTypes.bool,
      defaultOverall:            PropTypes.number,
      defaultDaily:              PropTypes.number,
      overallPatientsLimit:      PropTypes.number,
      dailyNewPatientLimit:      PropTypes.number,
      practiceTeamParticipantId: PropTypes.string,
      practiceTeamId:            PropTypes.string,
      currentPatients:           PropTypes.number
    }),
    setTempModalData:                PropTypes.func
  }),
};

export default InnerContent;
