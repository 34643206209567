import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, isArray, map, concat, find, uniq, isNumber, isNil } from 'lodash';
import { useUpdater } from '@td/shared_utils';
import { authToken } from '@td/api';
import moment from 'moment-timezone';
import jstz from 'jstz';
import ConsultationReschedulingSelection from './consultation-rescheduling-selection';
import ConsultationReschedulingSuccess from './consultation-rescheduling-success';
import Calendar from '../../datepicker/datepicker';

// Shared constants
export const SEND_MESSAGE_ACTION = 'send_message';
export const PROPOSE_DATE_ACTION = 'propose_date';

const ConsultationReschedulingModal = ({
  onClose,
  consultationId,
  memberId,
  providerId,
  requestedDates,
  isWebsdkRescheduleToggle,
  isTimeSlotOnly,
  reschedulingIntervals,
  intervalBetweenScheduling
}) => {
  // Private constants
  const RESCHEDULING_SELECTION_STEP = 'select';
  const RESCHEDULING_SUCCESS_STEP = 'success';
  const tz = jstz.determine().name();
  const currentTimezone = moment.tz(tz).zoneAbbr();
  const systemLocale = get(global, ['I18n', 'locale'], '');
  const locale = systemLocale === 'en' ? 'en-US' : systemLocale;
  // States
  const [currentStep, setCurrentStep] = useState(RESCHEDULING_SELECTION_STEP);
  const [selectedAction, setSelectedAction] = useState(isTimeSlotOnly ? SEND_MESSAGE_ACTION : null);
  const [memberRequestedDates, setMemberRequestedDates] = useState(null);
  const [error, setError] = useState(null);
  const [reschedulingResponse, setReschedulingResponse] = useState(RESCHEDULING_SELECTION_STEP);
  const [selectedDates, setDates] = useState([]);
  const [conflictErrors, setCurrentConflictErrors] = useState([]);
  const [editModeIndex, setEditModeIndex] = useState(null);
  const [hasErrors, setHasErrors] = useState(true)
  const consultationReschedulingModalRef = useRef(null)

  const getLockedTimeSlotsRequest = useUpdater('get', {
    url:     '/v4/consultations/locked_slots',
    method:  'get',
    headers: { Authorization: `${authToken.get()}` }
  });

  const checkTimeConflictRequest = useUpdater('get', {
    url:     '/v4/consultations/proposal_conflicts_with_existing_appointments',
    method:  'get',
    headers: { Authorization: `${authToken.get()}` }
  });

  const rescheduleRequest = useUpdater('post', {
    url:     `/consultations/${consultationId}/propose`,
    method:  'post',
    headers: { Authorization: `${authToken.get()}` },
    baseURL: window.location.origin
  });

  const requestedDatesParsed = requestedDates ? JSON.parse(requestedDates) : [];

  useEffect(() => {
    const requestedDatesFormatted = requestedDatesParsed.map(
      requestedDate =>
        `${moment(requestedDate).format('MM/DD/YYYY hh:mm A')} ${moment(requestedDate)
          .tz(moment.tz.guess())
          .format('zz')}`
    );
    setMemberRequestedDates(requestedDatesFormatted);
  }, [requestedDates]);

  useEffect(() => {
    getLockedTimeSlotsRequest.callApi({
      provider_id: providerId,
      member_id:   memberId
    });
  }, []);

  useEffect(() => {
    const checkSuccessWrapper = () => {
      const consultRescheduleModal = consultationReschedulingModalRef.current;
      if (consultRescheduleModal) {
        const successWrapper = consultRescheduleModal.querySelector('.consultationReschedulingSuccessWrapper');
        setHasErrors(!successWrapper);
      }
    }
    // Set up a MutationObserver to observe changes in the DOM
    const observer = new MutationObserver(checkSuccessWrapper)
    // Start observing changes within consultationReschedulingModal
    if (consultationReschedulingModalRef.current) {
      observer.observe(consultationReschedulingModalRef.current, { subtree: true, childList: true });
    }
    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect()
    };
  }, [])

  const handleSetSelectedAction = action => {
    if (action === SEND_MESSAGE_ACTION) {
      setDates([]);
    }
    setSelectedAction(action);
  };

  const handleSubmit = async (selectedDates, notes) => {
    const formattedDates = map(selectedDates, date => {
      const formattedDate = moment(date).format('M/D/Y h:mm A');
      const selectedDateTZ = moment(date)
        .tz(moment.tz.guess())
        .format('zz');
      // e.g.["07/08/2021 3:30 PM CEST"]
      return `${formattedDate} ${selectedDateTZ}`;
    });
    const params = {
      consultation: {
        scheduling_notes: notes || null,
        proposed_dates:   formattedDates,
        negotiation_type:
          selectedAction === SEND_MESSAGE_ACTION ? 'provider_ask_member_for_date_change' : 'provider_propose'
      }
    };
    rescheduleRequest.callApi(params);
  };

  const handleConfirm = action => {
    switch (action) {
      case 'my_schedule':
        window.location.href = '/calendar';
      case 'done':
        onClose();
      default:
        onClose();
    }
  };

  const handleSelectDates = params => {
    const { dates, dateToRemove, currentConflictErrors } = params;
    const index = selectedDates.indexOf(dateToRemove);
    if (index > -1) {
      const newConflictErrors = [...currentConflictErrors];
      newConflictErrors.splice(index, 1);
      setCurrentConflictErrors(newConflictErrors);
    }
    checkTimeConflictRequest.reset();
    setDates(dates);
  };

  const renderErrors = error => {
    if (isArray(error)) {
      const errors = map(error, e => <p>{e}</p>);
      return <div className="error">{errors}</div>;
    } else {
      return <div className="error">{error}</div>;
    }
  };

  const handleCheckTimeConflicts = (date, screen, editModeIndex, currentConflictErrors) => {
    setEditModeIndex(editModeIndex);
    if (currentConflictErrors && currentConflictErrors.length) {
      setCurrentConflictErrors(currentConflictErrors);
    }
    const params = {
      proposed_date: new Date(date).toISOString(),
      member_id:     memberId,
      screen_name:   'summary'
    };
    checkTimeConflictRequest.reset();
    checkTimeConflictRequest.callApi(params);
  };

  const getCollectedConflictErrors = () => {
    let collectedConflictErrors = [...conflictErrors];
    if (checkTimeConflictRequest && checkTimeConflictRequest.finished) {
      const conflictMessage = get(checkTimeConflictRequest, ['error', 'response', 'data', 'conflict_message']);
      if (!conflictMessage) {
        // Remove error message
        if (!isNil(editModeIndex)) {
          collectedConflictErrors[editModeIndex] = false;
        } else {
          collectedConflictErrors = concat(collectedConflictErrors, false);
        }
      } else {
        // Add error message
        if (editModeIndex) {
          collectedConflictErrors[editModeIndex] = conflictMessage;
        } else {
          collectedConflictErrors = concat(collectedConflictErrors, conflictMessage);
        }
      }
    }
    return collectedConflictErrors;
  };

  let lockedSlots = null;
  if (getLockedTimeSlotsRequest && getLockedTimeSlotsRequest.finished) {
    lockedSlots = uniq(get(getLockedTimeSlotsRequest, ['data', 'lockedSlots'], []));
  }

  const hasConflictErrors = find(getCollectedConflictErrors(), error => typeof error === 'string') !== undefined;
  const rescheduleRequestError = get(rescheduleRequest, ['error', 'response', 'data', 'error']);
  const canSubmit =
    !hasConflictErrors &&
    ((selectedAction &&
      (selectedAction === SEND_MESSAGE_ACTION ||
        (selectedAction === PROPOSE_DATE_ACTION && selectedDates.length > 0))) ||
      (!selectedAction && !isWebsdkRescheduleToggle && !isTimeSlotOnly && selectedDates.length > 0));

  return (
    <div className="consultationReschedulingModalWrapper" ref={consultationReschedulingModalRef}>
      {hasErrors && rescheduleRequestError && renderErrors(rescheduleRequestError)}
      {rescheduleRequest.finished && rescheduleRequest.data ? (
        <ConsultationReschedulingSuccess data={rescheduleRequest.data} onConfirm={handleConfirm} />
      ) : (
        <ConsultationReschedulingSelection
          timezone={currentTimezone}
          locale={locale}
          canSubmit={canSubmit}
          selectedAction={selectedAction}
          onSelectAction={action => handleSetSelectedAction(action)}
          onClose={() => onClose()}
          onSubmit={handleSubmit}
          memberRequestedDates={memberRequestedDates}
          onSelectDates={handleSelectDates}
          memberId={memberId}
          onCheckTimeConflicts={handleCheckTimeConflicts}
          conflictErrors={getCollectedConflictErrors()}
          selectedDates={selectedDates}
          lockedSlots={lockedSlots}
          isWebsdkRescheduleToggle={isWebsdkRescheduleToggle}
          isTimeSlotOnly={isTimeSlotOnly}
          reschedulingIntervals={reschedulingIntervals}
          intervalBetweenScheduling={intervalBetweenScheduling}
        />
      )}
    </div>
  );
};

ConsultationReschedulingModal.propTypes = {
  onClose:                  PropTypes.func.isRequired,
  consultationId:           PropTypes.string.isRequired,
  memberId:                 PropTypes.string.isRequired,
  providerId:               PropTypes.string.isRequired,
  isWebsdkRescheduleToggle: PropTypes.bool,
  isTimeSlotOnly:           PropTypes.bool,
  reschedulingIntervals:    PropTypes.string
};

export default ConsultationReschedulingModal;
