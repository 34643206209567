import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import AriaModal from './AriaModal';

const TeladocModal = ({ onClose, isOpen, children, showCloseButton, title, actions, className, closeIcon, parentClassName, contentClassName }) => {
  if (!isOpen) return null;
  return (
    <AriaModal
      mounted={isOpen}
      onExit={onClose}
      underlayClickExits={false}
      escapeExits={false}
      titleText={title}
      underlayClass={parentClassName}
    >
      <div className={`teladocModalWrapper ${className}`}>
        <div className="teladocModalContainer">
          <div className={`teladocModalContent ${!!contentClassName ? contentClassName : ''}`}>
            <div className="teladocModalHeader">
              <span className="teladocModalTitle">{title}</span>
              {showCloseButton && (
                <button
                  className={`teladocModalCloseButton ${!closeIcon ? 'defaultCloseIcon' : null}`}
                  onClick={() => onClose()}
                  aria-label="Close modal"
                >
                  {closeIcon && <img className="closeIcon" key="closeIcon" src={closeIcon} />}
                </button>
              )}
            </div>
            <div className="teladocModalBody">{children}</div>
            {actions && actions.length && (
              <div className="teladocModalActions">
                {map(actions, action => {
                  let actionClassNames = 'action declineDecline';
                  if (action.className) actionClassNames = `${actionClassNames} ${action.className}`;
                  actionClassNames = action.primary
                    ? `${actionClassNames} acceptAction`
                    : `${actionClassNames} declineAction`;
                  return (
                    <button
                      key={action.className || action.label}
                      className={actionClassNames}
                      onClick={() => action.onClick()}
                      disabled={action.disabled}
                    >
                      {action.label}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </AriaModal>
  );
};

TeladocModal.propTypes = {
  children:        PropTypes.node.isRequired,
  title:           PropTypes.string.isRequired,
  isOpen:          PropTypes.bool,
  showCloseButton: PropTypes.bool,
  className:       PropTypes.string,
  onClose:         PropTypes.func,
  parentClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  actions:         PropTypes.arrayOf(
    PropTypes.shape({
      label:     PropTypes.string.isRequired,
      className: PropTypes.string,
      primary:   PropTypes.bool,
      disabled:  PropTypes.bool,
      onClick:   PropTypes.func.isRequired
    })
  )
};

TeladocModal.defaultProps = {
  isOpen:          false,
  showCloseButton: true
};

export default TeladocModal;
