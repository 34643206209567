import React from 'react';
import newFeature from '../../../assets/images/new-feature-overview.svg';
import { translate } from '@td/shared_utils';

const InnerContent = () => {
  const alt = 'new-feature-overview-image';

  return (
    <div className="feature-overview-container">
      <ol className="instructions" type="1">
        <li>
          <p>
            <strong>
              {translate(
                null,
                'patient_case_load_mgmt_mvp.feature_overview_modal',
                'flip_between_patients_and_consults'
              )}
            </strong>
          </p>
          <p>
            {translate(
              null,
              'patient_case_load_mgmt_mvp.feature_overview_modal',
              'flip_between_patients_and_consults_instruction'
            )}
          </p>
        </li>
        <li>
          <p>
            <strong>
              {translate(null, 'patient_case_load_mgmt_mvp.feature_overview_modal', 'limit_patient_volume')}
            </strong>
          </p>
          <p>
            {translate(null, 'patient_case_load_mgmt_mvp.feature_overview_modal', 'limit_patient_volume_instruction')}
          </p>
        </li>
      </ol>
      <img src={newFeature} alt={alt} />
    </div>
  );
};

export default InnerContent;
