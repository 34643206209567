import React from 'react';
import TeladocModal from 'app/TeladocModal';
import InnerContent from './innerContent';
import PropTypes from 'prop-types';
import '../styles.scss';
import { teladocApi, authToken } from '@td/api';
import { translate } from '@td/shared_utils';
import { useData } from 'app/DataProvider';

const FeatureOverviewPatientLimits = ({ isOpen, setIsOpen }) => {
  const title = 'Introducing your new dashboard';

  const { providerId } = useData();

  const onClose = () => {
    setIsOpen(false);

    const createParams = {
      provider_id: providerId,
      alert_type:  'mvp_guided_alert'
    };

    teladocApi.post(`/v4/providers/${providerId}/alerts`, createParams, authToken.get());
  };

  const actions = [
    {
      className: '',
      primary:   false,
      onClick:   () => {
        setIsOpen(false);
      },
      disabled: false,
      label:    translate(null, 'patient_case_load_mgmt_mvp.feature_overview_modal', 'remind_me_later')
    },
    {
      className: '',
      primary:   true,
      onClick:   onClose,
      disabled:  false,
      label:     translate(null, 'patient_case_load_mgmt_mvp.feature_overview_modal', 'got_it')
    }
  ];

  return (
    <TeladocModal
      className={'feature-overview-modal-styles'}
      showCloseButton={false}
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      actions={actions}
      children={<InnerContent />}
    />
  );
};

FeatureOverviewPatientLimits.propTypes = {
  isOpen:    PropTypes.bool,
  setIsOpen: PropTypes.func
};

FeatureOverviewPatientLimits.defaultProps = {
  isOpen: false
};

export default FeatureOverviewPatientLimits;
