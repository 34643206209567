import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const RescheduleAction = ({ consultationId, memberId, providerId, websdkRescheduleToggle=false, isTimeSlotOnly }) => {
  return (
    <Fragment>
      <a
        id="consult_queues"
        className="reschedule requested_and_proposed_visits consultation_queue_list"
        data-consultation-id={consultationId}
        data-member-id={memberId}
        data-is-timeslot-only={isTimeSlotOnly}
        data-provider-id={providerId}
        data-websdk-reschedule-toggle={websdkRescheduleToggle}
        href={`/consultations/${consultationId}/propose_new_times_modal`}
        title={'Reschedule'}
      >
        Reschedule
      </a>
    </Fragment>
  );
};

RescheduleAction.propTypes = {
  consultationId: PropTypes.number.isRequired
};

export default RescheduleAction;
