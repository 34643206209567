import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Slider = ({ max, min, current, isFrozen, useInputBox = false, showCurrentPatients = false, value, setValue }) => {
  const verification = () => {
    if (showCurrentPatients && value < current) {
      setValue(parseInt(current));
    }
  };

  const handleChange = event => {
    if (isFrozen) return;
    let target = event.target.value;
    if (target && target < min) {
      target = min;
    } else if (target > max) {
      target = max;
    }
    setValue(parseInt(target));
  };

  const distanceFromStart = useMemo(() => value - min, [value]);
  const totalDistance = useMemo(() => max - min, [value]);
  const currentPatientsDistance = useMemo(() => current - min, [value]);
  const progressedWidth = useMemo(() => (distanceFromStart / totalDistance) * 100, [value]);
  const currentPatientsWidth = useMemo(() => (currentPatientsDistance / totalDistance) * 100, [value]);

  const color = isFrozen ? { backgroundColor: '#B4B4AF' } : {};
  const numberColor = isFrozen ? { color: '#B4B4AF' } : {};

  const currentIsGreaterThanHalf = current >= max / 2;

  const currentStyle = currentIsGreaterThanHalf ? { left: '-100px' } : {};
  const currentOrder = currentIsGreaterThanHalf ? { order: 0 } : { order: 1 };

  const knobStyle = { left: `calc(${progressedWidth}% - ${progressedWidth <= 1 ? 11 : 14}px)`, ...color };
  const inputBoxStyle = { left: `calc(${progressedWidth}% - ${progressedWidth <= 1 ? 19 : 22}px)` };
  const floatingValueStyle = {
    left: `calc(${progressedWidth}% - ${progressedWidth <= 10 ? 3 : progressedWidth >= 95 ? 11 : 5}px)`,
    ...numberColor
  };

  return (
    <div className="range-slider">
      <input
        className="slider"
        type="range"
        min={min}
        max={max}
        value={parseInt(value) || 0}
        onChange={handleChange}
        onBlur={verification}
      />
      {useInputBox ? (
        <input
          className="slider-input-box"
          type="number"
          value={parseInt(value) || 0}
          onChange={handleChange}
          onBlur={verification}
          style={inputBoxStyle}
          disabled={isFrozen}
        />
      ) : (
        <div className="slider-floating-value" style={floatingValueStyle}>
          {value}
        </div>
      )}
      <div className="slider-line">
        <div className="slider-line-progressed" style={{ width: `${progressedWidth}%`, ...color }} />
      </div>
      <div className="slider-knob" style={knobStyle} />
      {showCurrentPatients && (
        <div className="current-patients-line" style={{ left: `calc(${currentPatientsWidth}% + 4px)` }}>
          <p className="current-patients-label" style={currentStyle}>
            <span style={{ ...currentOrder, padding: '0 2px' }}>Current patients </span> <span>{current}</span>{' '}
          </p>
        </div>
      )}
      <div className="slider-labels">
        <span className="slider-label">{min}</span>
        <span className="slider-label">{max}</span>
      </div>
    </div>
  );
};

Slider.propTypes = {
  min:                 PropTypes.number,
  max:                 PropTypes.number,
  current:             PropTypes.number,
  useInputBox:         PropTypes.bool,
  showCurrentPatients: PropTypes.bool
};

Slider.defaultProps = {
  useInputBox:         false,
  showCurrentPatients: false
};

export default Slider;
