import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@td/shared_utils';
import TeladocModal from '../../TeladocModal';
import ConsultationReschedulingModal from '../../consultation-rescheduling/components/consultation-rescheduling-modal';

const TRANSLATION_SCOPE = 'my_schedule.event_details';

const ConsultationRescheduleContainer = ({
  consultationId,
  onModalClose,
  memberId,
  providerId,
  reschedulingIntervals
}) => (
  <TeladocModal onClose={onModalClose} isOpen title={translate(null, TRANSLATION_SCOPE, 'reschedule')}>
    <ConsultationReschedulingModal
      onClose={onModalClose}
      consultationId={consultationId}
      memberId={memberId}
      providerId={providerId}
      isWebsdkRescheduleToggle
      reschedulingIntervals={String(reschedulingIntervals)}
    />
  </TeladocModal>
);

ConsultationRescheduleContainer.propTypes = {
  consultationId:        PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onModalClose:          PropTypes.func.isRequired,
  memberId:              PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  providerId:            PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  reschedulingIntervals: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default ConsultationRescheduleContainer;
